import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import BASEURL, {
  getCaseInfo_endPoint,
  DataBaseName,
} from "../../Api/End_Points";

export const CaseApi = createApi({
  reducerPath: "CaseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
  }),
  tagTypes: ["Case"],
  endpoints: (builder) => ({
    getCaseInfo: builder.query({
      query: (caseId) =>
        `${getCaseInfo_endPoint}/${caseId}?databaseName=${DataBaseName}`,
      providesTags: ["Case"],
    }),
  }),
});

export const { useGetCaseInfoQuery } = CaseApi;
