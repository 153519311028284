import React, { useEffect, useState } from "react";
import { useGetCaseInfoQuery } from "../../RTK/api/case_Api";
import logo from "../../Assets/images/tek.png";

const SearchForm = () => {
  const [caseID, setCaseID] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, isLoading, error } = useGetCaseInfoQuery(caseID, {
    skip: !shouldFetch,
  });

  const handleSearch = () => {
    if (caseID) {
      setShouldFetch(true);
    }
  };

  const handleSearchAgain = () => {
    // Reset caseID and data by disabling fetch
    setCaseID("");
    setShouldFetch(false); // This will prevent refetching
  };

  useEffect(() => {
    if (!isLoading) {
      setShouldFetch(false);
    }
  }, [isLoading]);

  console.log(data);

  return (
    <div className="main">
      {!shouldFetch || !data ? (
        <div className="search_box">
          <img src={logo} alt="noontek_logo" />
          <div className="search_input">
            <input
              type="number"
              value={caseID}
              onChange={(e) => setCaseID(e.target.value)}
              placeholder="Enter case ID"
            />
            <button onClick={handleSearch}>Search</button>
          </div>
        </div>
      ) : (
        <div className="result_box">
          <img src={logo} alt="noontek_logo" />
          {error ? (
            <div className="Error">
              <p>{error?.data?.message}</p>
            </div>
          ) : (
            <div className="result_data">
              <h4>CASE #{data?.data?.counter}</h4>
              <p>Client Name : {data?.data?.userId?.userName}</p>
              <p>Device Model : {data?.data?.deviceId?.deviceModel}</p>
              <p>Device Problem : {data?.data?.deviceProblem}</p>
              <p>Case Reception Date : {data?.data?.deviceReceptionDate}</p>
              <p>Maintenance status : {data?.data?.manitencesStatus}</p>
            </div>
          )}

          <button onClick={handleSearchAgain}>Search antother Case</button>
        </div>
      )}
    </div>
  );
};

export default SearchForm;
