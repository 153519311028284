import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { CaseApi } from "./api/case_Api";

export const store = configureStore({
  reducer: {
    [CaseApi.reducerPath]: CaseApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(CaseApi.middleware),
});

setupListeners(store.dispatch);
